$background: linear-gradient(to right, #9d50bb, #6e48aa); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

$fontColor: #fff;

$inputBg: #fff;
$inputColor: #333;
$inputBorder: #ddd;
$inputShadow:  0 3px 6px rgba(0,0,0,0.3);

$buttonShadow:  1px 1px 4px rgba(0,0,0,0.2);

$fontFamily: 'WF Sarabun', sans-serif;
$headerFontFamily: 'WF Sarabun', sans-serif;
//'WF Prompt', sans-serif;

$primaryColor: #6e48aa;
$primaryTint: #fff;

$primaryLightColor: #9d50bb;
$primaryLightColorTint: #ffffff;

$secondaryColor: #92568F;
$secondaryColorTint: #fff;

$blackColor: #4B4B4B;

$blackTint: #fff;

$brightColor1: #3CCE00;
$brightColor2: #6F00B7;

$alertColor: #D7373F;
$infoColor: #127193;
$successColor: #38B42F;

$boxBorderRadius: 20px;