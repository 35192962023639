
.login-page {
	display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.login-form {
	width: 50%;
	max-width: 400px;
	display: flex;
	flex-direction: column;
}
.login-form-row {
	flex: 1;
	margin: 0 0 20px;
}
.login-form-bottom {
	flex: 1;
	margin: 20px 0 20px;
	
}
.main-logo {
	position: fixed;
	top: 20px;
	left: 20px;
}

#languageToggle {
	position: fixed;
	top: 20px;
	right: 20px;
	list-style: none;
	padding: 0;
	margin: 0;
}
#languageToggle button {
	background: transparent;
	border: none;
	display: inline-block;	
	color: #fff;
	outline: none;
}
#languageToggle button:first-child {
	border-right: 1px solid #fff;	
}