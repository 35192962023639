button:focus,
button:active {
	outline: none;
	border: 1px solid rgb(52, 139, 233);
	box-shadow: 0 0 2px 2px rgba(52, 139, 233 ,0.8)
}
.btn {
	background: #fff;
	color: $primaryLightColor;
	border: none;
	box-shadow: $inputShadow;
	font-size: 1.4rem;
	line-height: 1.4em;
	padding: 12px 80px;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	font-family: $headerFontFamily;
}
.btn-lg {
	font-size: 2rem;
	line-height: 1.4em;
	padding: 1em 3em;
}
.btn:focus,
.btn:active{
	border: 1px solid rgb(52, 139, 233);
	box-shadow: 0 0 2px 2px rgba(52, 139, 233 ,0.8)
}
.btn-round {
	border:none;
	background: #fff;
	color: $primaryLightColor;
	font-size: 18px;
	line-height: 26px;
	padding: 10px 30px;
	border-radius: 30px;
	font-weight: bold;
	box-shadow: $buttonShadow;
	display: inline-block;
	margin: 0;
	cursor: pointer;
  	font-family: $headerFontFamily;
}
.btn-lg-round {
	font-size: 2.1rem;
	line-height: 1.5em;
	padding: 0.6em 2em;
	font-weight: bold;
	border-radius: 30px;
	min-width: 350px;
}
.btn-black {
	background: $blackColor;
	color: $blackTint;
	font-weight: bold;
}
.btn-round-secondary {
	color: $secondaryColor;
}

.btn-subtle {
	border:none;
	background: transparent;
	color: #fff;
	font-size: 16px;
	line-height: 30px;
	padding: 0 20px;
	border-radius: 30px;
	font-weight: normal;
	box-shadow: none;
	display: inline-block;
	margin: 0;
	
	cursor: pointer;
  	font-family: $headerFontFamily;
}
.btn-link {
	border:none;
	background: transparent;
	color: #fff;
	line-height: 30px;
	padding: 0;
	border-radius: 30px;
	font-weight: normal;
	box-shadow: none;
	display: inline-block;
	margin: 0;
	vertical-align: middle;
	font-size: 22px;
	cursor: pointer;
  font-family: $headerFontFamily;
}
.btn-link img {
	vertical-align: middle;
}
.btn-block {
	display: block;
	text-align: center;
	width: 100%;
	padding-left: 5px;
	padding-right: 5px;
}
.btn-primary {
	background: $primaryColor;
	color: $primaryTint;
}
.btn-secondary {
	background: $secondaryColor;
	color: $secondaryColorTint;
}
.btn-icon {
	background: transparent;
	border: none;
	padding: 0px;
}
.btn-icon img {
	height: 20px;
}

.btn-icon-image {
	width:25px;
	display: inline-block;
	vertical-align: top;
	margin: 0 10px;
}

.btn-icon:focus, .btn-icon:active,
.btn-round:focus, .btn-round:active,
.btn:focus, .btn:active {
	border: 1px solid rgb(52, 139, 233);
	box-shadow: 0 0 2px 2px rgba(52, 139, 233 ,0.8)
}


.button {
	background: #fff;
	color: $primaryLightColor;
	border: none;
	box-shadow: $inputShadow;
	font-size: 1.8rem;
	line-height: 1.5em;
	padding: 12px 10px;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	font-family: $headerFontFamily;
	border-radius: 20px;
	font-weight: bold;
	border: 2px solid #fff;
	min-width: 200px;
	margin: 20px 10px;
	overflow: hidden;
}
.button-outline {
	border: 2px solid #fff;
	background: transparent;
	color: #fff;
	font-weight: normal;
}
.button-outline:focus,
.button-outline:active{
	border: 2px solid #fff;
	box-shadow: $inputShadow;
}

.mini-check {
	margin: 0;
	border: 0;
	background: #fff;
	color: $primaryColor;
	display: inline-block;
	overflow: hidden;
	-webkit-appearance: none;
	outline: none;
	width: 16px;
	padding: 3px 0 0 0;
	text-align: center;
	height: 16px;
	border-radius: 2px;
	font-size: 12px;
	box-shadow: 0 0 1px rgba(0,0,0,0.3);
}

.mini-check i {
	display: inline-block;
	line-height: 1em;
}