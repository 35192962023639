.action-box {
	border: 2px solid #fff;
	border-radius: $boxBorderRadius;
	display: flex;
	overflow: hidden;
	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
	font-size: 22px;
	margin: 0 0 10px;
}
.action-box p {
	margin-bottom: 15px;	
}
.action-box p:last-child {
	margin-bottom: 0;	
}
.action-box-image {
	background: #fff;
	width: 140px;
	padding: 15px 20px;
}
.action-box-content {
	padding: 15px 20px 0;
	flex: 1;
}
.action-box-content strong {
	font-size: 28px;	
}
.card-reader {
	display: inline-block;
}
.register-form-footer {
	display: flex;
}
.register-form-footer-table {
	flex: 1;
}
.register-form-footer-buttons {
	width: 350px;
	padding: 20px 20px 0;
	
}
.register-cancel {
	font-size:14px;
	opacity:0.8;
	display: inline-block;
	margin: 0 0 0 30px;
	text-decoration: underline;
}

.select-program {
	display: flex;
}
.select-program-display{
	flex: 1;
}
.select-program-button {
	
}

.modal-body-program {
	width: 90%;
	height: 90%;
	border-radius: $boxBorderRadius;
	background: #fff;
	color: $primaryColor;
	overflow: hidden;
	
	box-shadow: $inputShadow;
}
.modal-body-program-header {
	display: flex;
	height: 40px;
	line-height: 40px;
	font-weight: bold;
	background: $primaryColor;
	color: $primaryTint;
	font-family: $headerFontFamily;
}

.modal-body-program-header > div:nth-child(2) {
  flex: 1;
}
.modal-body-program-header > div:first-child {
	width: 20%;	
}
.modal-body-program-header > div:last-child {
	width: 60%;	
}
.modal-body-program-content {
	height: unquote("calc(100% - 110px)");
	overflow-x: hidden;
	overflow-y: scroll;
}
.modal-body-program-table {
	width: 100%;
	border: 1px solid #fff;	
	border-spacing: 0;
}
.modal-body-program-table td {
	border: 1px solid #fff;	
	line-height: 30px;
	padding: 5px !important;
	margin: 0;
	color: darken($primaryColor,20);
	cursor: pointer;
}
.modal-body-program-table td:first-child {
	padding-left: 20px !important;	
}
.modal-body-program-table tr:nth-child(odd) {
	background: lighten($primaryColor,50);
}
.modal-body-program-table tr:nth-child(even) {
	background: lighten($primaryColor,45);
}
.modal-body-program-footer {
	height: 70px;
	border-top: 1px solid rgba(0,0,0,0.05);
	box-shadow: -1px 0 3px rgba(0,0,0,0.1);
	z-index: 9;
	padding: 10px;
	display: flex;
	text-align: left;
}
.modal-body-program-footer > div {
	padding:  0 0 0 10px;
}
div.modal-body-program-footer-program {
	text-align: right;
	font-size: 20px;
	line-height: 50px;
	flex: 1;
	padding-right: 20px;
}
.modal-body-program-footer-package {
	flex: 1;
	margin-left: 20px;
	font-size: 18px;
	display: flex;
	align-items: center;
	align-content: flex-end;
}
.modal-body-program-footer-package > div {
	flex: 1;	
	text-align: right;
}
.modal-body-program-footer-button {
	text-align: right;
}
.modal-body-program-footer-button button {
	margin: 0 0 0 10px;
}
.modal-body-program-table .fa,
.modal-body-program-table .far, {
	font-size: 26px;
	line-height: 26px;
	vertical-align: top;
	margin-right: 10px;
}


.modal-body-package {
	width: 90%;
	height: 90%;
	border-radius: $boxBorderRadius;
	background: #fff;
	color: $secondaryColor;
	overflow: hidden;
	
	box-shadow: $inputShadow;
}
.modal-body-package-header {
	display: flex;
	height: 40px;
	line-height: 40px;
	font-weight: bold;
	background: $secondaryColor;
	color: $secondaryColorTint;
	font-family: $headerFontFamily;
}

.package-table-btn {
	font-size: 16px;
	line-height: 1.5em;
	padding: 6px 0;
	color: #fff;
	text-align: center;
	display: block;
	width: 100%;
	font-weight: bold;
	background: transparent;
}
.package-table-btn img {
	vertical-align: top;
	height: 20px;
	margin: 2px 0 0 0;
}
.modal-body-package-header > div {
	width: 50%;
}
.modal-body-package-content {
	height: unquote("calc(100% - 110px)");
	overflow: hidden;
}
.modal-body-package-content-list {
	float: left;
	width: 49.5%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	list-style: none;
	padding: 0;
	margin: 0;
	background: lighten(#E8DDE8,2);
}
.modal-body-package-content-item {
	display: block;
	border-bottom: 2px solid darken(#DDCDDD,5);
	text-align: left;
	padding: 10px;
	
	cursor: pointer;
}
.modal-body-package-content-item.selected {
	
	background: darken(#E8DDE8,2.5);
}
.modal-body-package-content-item-header {
	display: flex;
	flex-direction: row;
}
.modal-body-package-content-item-select {
	width: 30px;
	text-align: center;
}
.modal-body-package-content-item-desc {
	flex: 1;
}
.modal-body-package-content-item-help {
	position: relative;
}
.modal-body-package-content-item-help > small {
	position: absolute;
	font-size: 14px;
	padding: 0 5px;
	line-height: 1.5em;
	right:25px;
	z-index: 10;
	background: #fff;
	border-radius: 3px;
	display: none;
}
.modal-body-package-content-item-help:hover > small {
	display: block;
}
.modal-body-package-content-lab {	
	float: right;
	width: 49.5%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
}
ul.modal-body-package-content-lab-list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	padding: 5px;
}
ul.modal-body-package-content-lab-list > li {
	display: block;
	width: unquote("calc(50% - 10px)");
	text-align: left;
	padding: 5px;
	position: relative;
	margin: 0 5px 5px 0;
	background: lighten(#DDCDDD,2);
	cursor: pointer;
}
ul.modal-body-package-content-lab-list > li.header {
	width: 100%;
	background: transparent;
	font-weight: bold;
	font-size: 20px;
	padding: 0;
	margin: 0;	
}
ul.modal-body-package-content-lab-list > li > label {
	display: block;
}
ul.modal-body-package-content-lab-list > li > .help {
	position: absolute;
	top: 5px;
	right:5px;
}

ul.modal-body-package-content-lab-list > li > .help > .help-info {
	position: absolute;
	font-size: 14px;
	padding: 0 5px;
	line-height: 1.5em;
	right:0;
	top: -25px;
	z-index: 10;
	background: #fff;
	border-radius: 3px;
	display: none;
	box-shadow: 0 0  2px rgba(0,0,0,0.3);
}
ul.modal-body-package-content-lab-list > li > .help:hover > .help-info {
	display: block;
}
ul.modal-body-package-content-lab-list > li .fa,
ul.modal-body-package-content-lab-list > li .far {
	margin: 0 8px 0 5px;
}
ul.modal-body-package-content-lab-list > li.default-lab {
	background: #ddd;
	color: #888;
	pointer-events: none;
}
ul.modal-body-package-content-lab-list > li.default-lab.selected-lab {
	background: #ddd;
	color: $alertColor;
	pointer-events: auto;
}
.modal-body-package-footer {
	height: 70px;
	border-top: 1px solid rgba(0,0,0,0.05);
	box-shadow: -1px 0 3px rgba(0,0,0,0.1);
	z-index: 9;
	padding: 10px;
	display: flex;
	text-align: left;
}
.modal-body-package-footer > div {
	padding:  0 0 0 10px;
}
div.modal-body-package-footer-package {
	flex:1;
	margin-left: 20px;
	font-size: 18px;
	display: flex;
	align-items: center;
	align-content: flex-end;
}
div.modal-body-package-footer-package > div {
	width: 100%;
	text-align: right;
}
div.modal-body-package-footer-package strong {
	white-space: nowrap;
}
div.modal-body-package-footer-package small {
	font-size: inherit;
	text-align: left;
	padding: 0 0 0 5px;
}
.modal-body-package-footer-button {
	text-align: right;
}
.modal-body-package-footer-button button {
	margin: 0 0 0 10px;
}

.package-table-btn-complex {
	display: flex;
}
.package-table-btn-left {
	width: 200px;
	text-align: right;
	font-family: $headerFontFamily;
}
.package-table-btn-mid {
	flex: 1;
	text-align: left;
	padding: 0 0 0 20px;
}
.package-table-btn-end {
	padding: 0 20px 0 0;
}

.package-table-btn-price {
	display: block;
	text-align: left;
	padding: 5px 20px 0 220px;
}
.package-table-btn-price strong {
	font-size: 26px;	
}

.card-reader-result {
	float: left;
	text-align: center;
	width: 40px;
}
.card-reader-result-use {
	background: #fff;
	border: none;
	color: $primaryColor;
	line-height: 20px;
	padding: 5px 0 4px;
	display: block;
	width: 100%;
	text-align: center;
	border-radius: 10px;
	outline: none;
}

.validate-error {
	position: relative;
	color: #ff0000;
	float: right;
}
.validate-error-items {
	position: absolute;
	top: 0;
	right: 0;
	white-space: nowrap;
	line-height: 40px;
	font-size: 18px;
}

.frame-sticker-print {
	width: 100%;
	height: unquote("calc(100vh - 60px)");
	border: none;
	text-align: center;
	margin: 0 auto;
}
.sticker-print-wrapper {
	height: 100vh;
}
.sticker-print-list {
	height: 100%;
	background: #C6C6C6;
	padding: 30px;
	width: 70%;
	float: left;
}
.sticker-print-buttons {
	height: 100%;
	width: 30%;
	float: right;
	text-align: center;
	padding: 30px;
}
.sticker-list {
	box-shadow: 0 0 5px rgba(0,0,0,0.5);
	list-style: none;
	padding: 20px;
	margin: 0;
	background: #fff;
	color: #000;
	font-size: 10px;
	line-height: 1.1em;
	text-align: left;
}
.sticker-list > li {
	width: 4cm;
	height: 2cm;
	display: inline-block;
	text-align: left;
	background: #fff;
	overflow: hidden;
	position: relative;
	border: 1px solid #666;
	padding: 5px;
	margin: 5px;
}
.sticker-list > li > svg {	
	position: absolute;
	bottom: -5px;
	z-index: 1;
}
.sticker-list > li > small,
.sticker-list > li > strong {
	display: block;
	padding: 0;
	margin: 0;
	clear: none;
	z-index: 2;
	position: relative;
}
.sticker-list > li > strong {
	float: right;
	clear: none;
	font-size: 1.2em;	
}

.table-input-row {
	display: flex;
}
.table-input-column:first-child {
	flex: 1;
}