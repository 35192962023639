.select-management {
	list-style: none;
	display: grid;
	grid-column: 2;
	grid-template-columns: 1fr 1fr;
	grid-gap: 40px;
	padding: 0;
	margin: 0;
}
.select-management li  {
	background: #fff;
	padding: 20px 0;
	text-align: center;
	border-radius: 10px;
	font-size: 26px;
	line-height: 1.3em;
	box-shadow: $inputShadow;
}
.select-management li:first-child  {
	padding: 0;
	grid-column: span 2;
}
.select-management a  {
	color: $primaryColor;
	display: block;
	padding: 30px 10px;
}
.select-management i {
	font-size: 50px;	
	clear: both;
	display: block;
	margin: 0 0 15px;
}
.progress-count {
	margin: 0;
	padding: 0;
	width: 100px;
	height: 18px;
	display: block;
	background: rgba(255,255,255,0.2);
	position: relative;
}
.progress-count span {
	display: block;
	background: darken($primaryColor,5);
	opacity:1;
	height: 100%;
	width: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	padding: 0;
	margin: 0;
}
.progress-count em {
	display: block;
	height: 100%;
	width: 100%;
	color: #fff;
	font-style: normal;
	position: relative;
	z-index: 2;
	font-size: 12px;
	line-height: 1.6em;
	padding: 0;
	margin: 0;
}


.barcode-form {
	width: 100%;
	height: 100%;
	display: block;
	padding: 0;
	margin: 0;
	position: relative;	
	background: #fff;
}
.barcode-input {
	display: block;
	width: 100%;
	height: 100%;
	outline: none;
	padding: 3px 70px 3px 10px;
	background: #fff;
	border: none;
}
.barcode-form-btn {
	position: absolute;
	top: 5px;
	right: 2px;
	background: transparent;
	border: none;
}	

.specimen-batch {
	display: flex;
	height: unquote("calc(100% - 80px)");
}
.specimen-batch-content {
	flex: 1;
	height: 100%;
}
.specimen-batch-action {
	padding-left: 20px;
	width: 220px;
}
table.specimen-batch-count { 
	display: block;
	height: 100%;
	width: 100%;
	overflow-y: auto;
}
table.specimen-batch-count thead,
table.specimen-batch-count tbody,
table.specimen-batch-count tr { 
	display: block;
	width: 100%;
}
table.specimen-batch-count tr {
	display: flex;
}
table.specimen-batch-count thead th { 
	position: sticky; 
	top: 0; 
}

.specimen-batch-action {
	display: flex;
	flex-direction: column;
}
.specimen-batch-action-info {
	flex: 1;
}
.specimen-batch-action-buttons {
	height: 150px;
}
.waiting-for-scan {
	text-align: center;
	font-size: 30px;
	font-weight: bold;
	padding: 50px 0 0 0;
}


.pe-form-wrapper {
	max-width: 530px;
	margin: 10px auto;
}

.pe-form-footer {
	box-shadow: 0 -1px 5px rgba(0,0,0,0.2);
	display: flex;
	padding: 15px 20px;
	height: 80px;
	background: darken($primaryColor,0.8)
}
.pe-form-footer-info {
	display: flex;
	flex:1;
	align-items: center;
	font-size: 20px;
}
.pe-form-footer-info p {
	margin: 0 20px 0 0;
}
.pe-form-footer-button {
	width: 300px;
}

.pe-fields {
	line-height: 35px;
	border: 2px solid #fff;
	padding: 0;
	margin: 0 0 20px;
	font-weight: bold;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.pe-field-row {
	display: flex;
	padding: 10px 6px;
}
.pe-field-row:nth-child(even){
	background: rgba(255,255,255,0.2);
}
.pe-field-group {
	display: flex;
	padding: 0 10px;
}
.pe-field-row-icon {
	width: 35px;
	font-size: 14px;
	text-align: right;
}
.pe-field-input {
	color: #333;
	width: 60px;
	margin: 0 10px;
	border-radius: 20px;
	border: none;
	font-size: 18px;
	line-height: 33px;
	box-shadow: $inputShadow;
	outline: none;
	text-align: center;	
	border: 1px solid #fff;
}
.pe-field-input:active,
.pe-field-input:focus {
	border: 1px solid rgb(52, 139, 233);
	box-shadow: 0 0 2px 2px rgba(52, 139, 233 ,0.8)
}

.pe-list {
	width: 100%;
	line-height: 35px;
	border: 2px solid #fff;
	padding: 0;
	margin: 0 0 20px;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	border-collapse: collapse;
}
.pe-list .pe-list-item:nth-child(even){
	background: rgba(255,255,255,0.2);
}
.pe-list-item {
	display: flex;
	padding: 3px 6px;
}
.pe-list-item .pe-list-item-title {
	width: 140px;
}
.pe-list-item-radio {
	border: none;
	background: transparent;
	color: #fff;
}
.pe-list-item-content {
	flex: 1;
	display: flex;
}
.pe-list-item-comment {
	flex: 1;
}
.pe-list-item .pe-list-item-note {
	flex: 1;	
	padding: 0;
}
.pe-list-item-select {
	display: flex;
}
.pe-list-item-select > div {
	padding: 0 10px 0 0;
	cursor: pointer;
}
.pe-list-item .pe-note {
	color: #333;
	background: #fff;
	padding: 2px 5px;
	border-radius: 5px;
	border: none;
	font-size: 16px;
	line-height: 28px;
	outline: none;
	text-align: left;	
	display: block;
	width: 100%;
	border: 1px solid #fff;
}
.pe-list-item .pe-note:active,
.pe-list-item .pe-note:focus {
	border: 1px solid rgb(52, 139, 233);
	box-shadow: 0 0 2px 2px rgba(52, 139, 233 ,0.8)
}


.visit-overview-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: unquote("calc(100vh - 60px)");
}
.visit-overview-content {
	height: 100%;
	display: flex;
	flex-direction: row;
}
.visit-overview-footer {
	box-shadow: 0 -1px 5px rgba(0,0,0,0.2);
	display: grid;
	padding: 15px 20px;
	height: 80px;
	background: darken($primaryColor,0.8);
  grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
}
.visit-overview-info {
	padding: 20px 40px;
	height: 100%;
	flex: 4;
	background: rgba(255,255,255,0.15);
	overflow-x: hidden;
	overflow-y: auto;
}
.visit-overview-result {
	background: rgba(255,255,255,0.05);
	padding: 20px 40px;
	height: 100%;
	flex: 4;
	overflow-x: hidden;
	overflow-y: auto;
}
.visit-overview-actions {
	flex: 2;
	padding: 50px 20px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
}
.visit-overview-actions p {
	display: block;
	width: 100%;
	text-align: center;
}
.visit-overview-actions {
	
}

.visit-overview-table {
	width: 100%;
	border-collapse: collapse;
	border: none;
	margin: 0 0 10px;
}
.visit-overview-table th {
	text-align: right;
	padding-right: 20px;
}
.visit-overview-table td,
.visit-overview-table th {
	font-size: 16px;
	vertical-align: top;
	line-height: 1.6em;
	padding-top: 4px;
	padding-bottom: 4px;
}
.visit-overview-table-specimens th {
	text-align: center;
	background: #fff;
	color: $primaryColor;
}
.visit-overview-table-specimens tr:nth-child(even) td {
	background: rgba(255,255,255,0.2);
}
.visit-overview-table-specimens td {
	text-align: center;
	padding: 4px;
}
.visit-overview-table-specimens td:last-child {
	text-align: center;
}
.visit-overview-info h5 {
	margin-bottom: 5px;
}
.visit-overview-row {
	display: flex;
}
.visit-overview-row > div {
	flex: 1;	
}
.visit-overview-table tr.addition {
	background: #948EC2;
}