.admin-form-footer {
    text-align: center;
    margin: 10px;
}
.visit-history {
    border: 1px solid #fff;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.visit-history-header {
    background: rgba(255,255,255,0.7);
    color: $primaryColor;
    box-shadow:  0 2px 5px rgba(0,0,0,0.2);

    h3 {
        background: #fff;
        color: $primaryColor;
        font-size: 1.2em;
        line-height: 1.8em;
        margin: 0;
    }

    .visit-history-age {
        float: left;
        padding: 3px;
        font-weight: bold;
    }
    .visit-history-height {
        float: right;
        padding: 3px;
        font-weight: bold;
    }
}
.visit-history-body {
    font-size: 0.9em;
    flex: 1;
    overflow-y: scroll;
    padding: 5px;
    line-height: 1.6em;

    .visit-history-item {
        padding: 5px;
        border-bottom: 1px solid #fff;
    }
    .visit-history-item-date {
        float: left;
    }
    .visit-history-item-weight {
        float: right;
    }
    .visit-history-item-sessions {
        clear: both;
        list-style: none;
        padding: 0;
        margin: 3px 0;
    }
    .visit-history-item-sessions li:nth-child(odd) {
        background: rgba(0,0,0,0.1);
    }
    .visit-history-item-sessions span {
        width: 100px;
        text-align: center;
        display: inline-block;
    }
}
.visit-history-footer {
    padding: 10px;
    text-align: center;
    background: rgba(0,0,0,0.08);
}