/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 21, 2017 */



@font-face {
    font-family: 'WF Prompt';
    src: url('prompt-light-webfont.woff2') format('woff2'),
         url('prompt-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'WF Prompt';
    src: url('prompt-lightitalic-webfont.woff2') format('woff2'),
         url('prompt-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'WF Prompt';
    src: url('prompt-semibold-webfont.woff2') format('woff2'),
         url('prompt-semibold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'WF Prompt';
    src: url('prompt-semibolditalic-webfont.woff2') format('woff2'),
         url('prompt-semibolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}