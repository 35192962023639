input.form-control {
	background: $inputBg;
	color: $inputColor;
	border: 1px solid $inputBorder;
	box-shadow: $inputShadow;
	font-size: 1.2rem;
	line-height: 2rem;
	padding: 8px 20px 5px;
	display: block;
	width: 100%;
	border-radius: 50px;
	outline: none;
}

select.form-control {
	color: $inputColor;
	border: 1px solid $inputBorder;
	box-shadow: $inputShadow;
	font-size: 1.2rem;
	line-height: 2rem;
	padding: 8px 20px 5px;
	display: block;
	width: 100%;
	margin: 0;
  	background-color: $inputBg;
  	background-image: url('../images/arrow-down.svg');
  	background-repeat: no-repeat, repeat;
  	background-position: right .7em top 50%, 0 0;
  	background-size: 20px auto, 100%;
  	outline: none;
	appearance: none;
	-webkit-appearance: none;
	border-radius: 50px;
}



input.form-control:focus,
input.form-control:active {
	border: 1px solid rgb(52, 139, 233);
	box-shadow: 0 0 2px 2px rgba(52, 139, 233 ,0.8)
}

input.large-input {
	font-size: 2.1rem;
	line-height: 1.5em;
	padding: 0.6em 0.3em;
	font-weight: bold;
	border-radius: 30px;
	min-width: 350px;
	border: 2px solid #fff;
	background: transparent;
	text-align: center;
	color: #fff;
	text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
	box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}
input.large-input:focus,
input.large-input:active {
	border: 2px solid #fff;
	box-shadow: 1px 1px 4px rgba(0,0,0,0.6);
	outline: none;
}

select.form-control::-ms-expand {
  display: none;
}
select.form-control:hover {
}
select.form-control:focus {
}
select.form-control option {
  font-weight:normal;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.number-pad {
	margin: 40px 0 0 0;
	display: flex;
}
.number-pad-main {
	flex: 1;
	margin: 0 40px 0 0;
}
.number-pad-numbers {
	width: 200px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);

	grid-gap: 10px;

	button {
		display: block;
		width: 100%;
		height: 55px;
		background: transparent;
		border: 2px solid #fff;
		border-radius: 5px;
		font-weight: bold;
		font-size: 24px;
		color: #fff;
		text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
		box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
	}
	button:hover {
		background: rgba(255,255,255,0.1);
	}
	button:focus,
	button:active {
		background: rgba(255,255,255,0.2);
		outline: none;
		border: 2px solid #fff;
		box-shadow: 1px 1px 4px rgba(0,0,0,0.6);
	}
}
.number-pad-screen {
	margin: 0 0 40px;
}
.number-pad-button {
	
}

.vital-signs {
	display: flex;
	gap: 10px;
	margin: 0 0 20px;
}