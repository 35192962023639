.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.pull-left {
	float: left;
}
.pull-right {
	float: right;
}
.text-left {
	text-align: left;
}