.dashboard-page {
	display: flex;
  	height: 100vh;
  	justify-content: center;
  	align-items: center;
  	text-align: center;
}
.main-logo {
	position: fixed;
	top: 20px;
	left: 20px;
}
.select-station {
	clear: both;
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: center;
	display: flex;
}
.select-station li {
	margin: 20px 30px;
	font-size: 26px;
	font-weight: bold;
}
.select-station-logo {
	display: block;
	width: 250px;
	height: 250px;
	background: #FFF;
	box-shadow: 0 5px 10px rgba(0,0,0,0.2);
	margin: 0 0 15px;
	border-radius: $boxBorderRadius;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.select-station-logo img {
	height: 130px;
}
.select-station li .color-1{
	background: $brightColor1;
}
.select-station li .color-2{
	background: $brightColor2;
}
.action-box-content small {
	font-size: 12px;
}

.setting-page {
	display: flex;
	height: 100vh;
	justify-content: center;
	align-items: center;
	text-align: center;
}